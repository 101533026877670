import {mapActions, mapGetters, mapMutations } from 'vuex'
import { doQuery, doAction, doBaiduGet,doBaidu,doChat } from '@/api/core'
import { getRewardMsg, replyRequestReward, qUpload, addRequestReward } from '@/api/consult'
import dateUtils from '@/utils/dateUtils.js'
import {switchEmoji, switchExpression} from '@/utils/emojiUtil'

export default {
    name: "manualConsult",

    data() {

        return {

            //过来的传送的数据，AI模式需要
            data_4_robot: {
                content: null,
                img1: null,
                img2: null,
                img3: null,
            },

            //机器人头像
            robot_img: 'http://public.wencaishui.com/images/wcs/xiaocai.png',

            //ai接口需要的信息
            channels_select: ['wechat', 'web', 'cc'],
            channel: 'web',

            //左边的我的历史咨询列表
            myQuestionList:[],
            myQuestionListEnd: false,
            myQuestionListPage: 1,
            myQuestionListLimit: 10,
            myQuestionListIndex: 0,

            //人工咨询的补充信息等
            requestAddinfo: null,

            //当前显示的历史对话记录
            chat_data:[],

            //表情
            emojiUl: false,
            emojiArray: [
                {text: "[发怒]", path: "11.gif"},
                {text: "[撇嘴]", path: "01.gif"},
                {text: "[疑问]", path: "32.gif"},
                {text: "[得意]", path: "04.gif"},
                {text: "[流泪]", path: "05.gif"},
                {text: "[骷髅]", path: "37.gif"},
                {text: "[呲牙]", path: "13.gif"},

                {text: "[微笑]", path: "00.gif"},
                {text: "[憨笑]", path: "28.gif"},
                {text: "[比心]", path: "91.gif"},
                {text: "[白眼]", path: "22.gif"},
                {text: "[难过]", path: "15.gif"},
                {text: "[发呆]", path: "03.gif"},
                {text: "[衰]", path: "36.gif"},
                {text: "[调皮]", path: "12.gif"},
                {text: "[愉快]", path: "21.gif"},
                {text: "[爱心]", path: "66.gif"},
                {text: "[惊恐]", path: "26.gif"},
                {text: "[惊讶]", path: "14.gif"},
                {text: "[擦汗]", path: "40.gif"},
                {text: "[敲打]", path: "38.gif"}
            ],

            //当前咨询的内容信息
            content: '',

            //上方显示的信息
            leftLocation: '',
            leftName: '',
            leftHeader: '',
            rightHeader: '',

            //是否采纳和评价
            boAdopted: null,
            boEvalueated: null,

            //是否新咨询
            boNewChat: true,

            //消息缓存
            socketMsgQueue: [],

            //历史咨询搜索关键字
            keywords: undefined,

            //评估窗口是否可见
            evaluateVisible: false,
            starLength: 5,
            evaluateObj: {
                a: false,
                b: false,
                c: false,
                d: false,
                e: false,
                f: false
            },
            evaluateText: undefined,

            //消息检查定时器
            checkMsg: null,

            //是否正在转接
            transfering: false

        }
    },

    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo',
            'myStaffInfo',
            'boQwsValid',
            'boNwsValid'
        ]),

        //当前咨询是否已经关闭
        boChatClosed(){

            if (!this.myQuestionList || this.myQuestionList.length == 0)
                return false;

            if ((this.myQuestionList[this.myQuestionListIndex].type == 'manual' && this.myQuestionList[this.myQuestionListIndex].status == 'e') ||
                (this.myQuestionList[this.myQuestionListIndex].type == 'ai' && this.myQuestionList[this.myQuestionListIndex].status == '1'))
                return true;
            else
                return false;

        }

    },

    mounted() {
        const that = this

        //登录
        if(this.mobilephone && !this.qToken) {
            this.QLogin(this.mobilephone)
        }

        //通知消息的socket
        this.openOrResetNoticeSocket()

        //如果传入了content（新咨询）
        if (this.$route.params && this.$route.params.content) {
            that.data_4_robot = {
                content: this.$route.params.content,
                img1: this.$route.params.img1,
                img2: this.$route.params.img2,
                img3: this.$route.params.img3,
            }
            that.sendAiMsg(that.data_4_robot.content, (callback) => {
                that.loadMyAwardHistory(1,() => {
                    if (callback) {
                        callback()
                    }
                })
            })
        }

        //否则加载历史咨询
        else {
            that.loadMyAwardHistory(1)
        }
    },

    methods: {
        ...mapActions([
            'QLogin'
        ]),
        ...mapMutations([
            'INIT_WS',
            'SEND_MSG',
            'RESET_HEART_CHECK',
            'CLOSE_WS',
            'INIT_NOTICE_WS',
            'RESET_NOTICE_WS_ONMESSAGE',
        ]),

        showChatData(index) {
            const that = this

            if (that.myQuestionList[index].type == 'ai'){

                //ai: 只要获取ai咨询历史即可
                that.refreshBotSessionLog()

            } else {

                //manual：要首先获取ai咨询历史，然后再获取人工咨询历史
                that.refreshBotSessionLog(() => {
                    that.refreshAddinfo(() => {
                        that.refreshRewardMsg()
                    })
                })

            }
        },

        changeChatIndex(index) {
            const that = this
            if(that.transfering) {
                that.$message.error('当前问题正在转接中，请稍等片刻...')
                return
            }

            const {myQuestionList} = that
            that.myQuestionListIndex = index
            that.chat_data = []

            if(myQuestionList.length > 0) {
                myQuestionList[index].unread = 0
                that.$set(that.myQuestionList,index,myQuestionList[index])
                that.showChatData(index)
            }else {
                that.chat_data = []
            }
        },

        switchManual() {
            const that = this

            if(that.myStaffInfo.dept_type == '1') {
                that.$error({title:'抱歉',content:'您的部门暂不支持转人工专家。',centered:true})
                return;
            }


            var data = {}
            that.showTip('开始为您转接人工，请稍候...');
            data.content = that.data_4_robot.content
            data.award_fee = 0
            data.requesttype_code = '1Z'
            data.img1 = that.data_4_robot.img1 ? that.data_4_robot.img1 : ''
            data.img2 = that.data_4_robot.img2 ? that.data_4_robot.img2 : ''
            data.img3 = that.data_4_robot.img3 ? that.data_4_robot.img3 : ''
            data.app_name = ''
            data.award_fee = 0
            data.award_prodid = 'C22A'
            data.pay_mode = 0
            data.coupon_no = ''
            data.kid = that.myQuestionList[that.myQuestionListIndex].ref_kid

            setTimeout(()=>{
                var ele = document.getElementById('chat-body');
                ele.scrollTop = ele.scrollHeight;
            },200)

            addRequestReward(data)
                .then(res1 => {
                    if (res1.errcode >= 0) {
                        that.showTip('已经成功提交，正在等待专家响应');
                        that.loadMyAwardHistory(1,() => {
                            that.refreshAddinfo(() => {
                                that.refreshRewardMsg()
                            })
                        })

                    } else {
                        if (res1.errcode == '1023') {
                            that.$message.error('转人工失败，可能含有敏感词')
                        } else {
                            that.$message.error(res1.errmsg)
                        }
                    }
                })
        },

        showTip(msg,icon){

            this.chat_data.push({
                direction: 'l',
                msg: msg,
                time: this.$cmyUtils.getCurrentTime(),
                type: 'info',
                icon: (icon ? icon : '')
            })

        },

        setAiSessionId(sessionId){

            const that = this;

            //增加检查：如果此时没有sessionID，则添加上
            if (that.myQuestionList &&
                that.myQuestionList.length > 0 &&
                that.myQuestionListIndex < that.myQuestionList.length &&
                that.myQuestionList[that.myQuestionListIndex].type == 'ai' &&
                that.myQuestionList[that.myQuestionListIndex].status == '0' &&
                (!that.myQuestionList[that.myQuestionListIndex].ref_kid || that.myQuestionList[that.myQuestionListIndex].ref_kid == '') &&
                (sessionId && sessionId != '')) {

                //保存sessionid
                that.$set(that.myQuestionList[that.myQuestionListIndex], 'ref_kid', sessionId)

            }
        },

        setAiChatId(chatId){

            const that = this;

            //增加检查：如果此时没有sessionID，则添加上
            if (that.myQuestionList &&
                that.myQuestionList.length > 0 &&
                that.myQuestionListIndex < that.myQuestionList.length &&
                that.myQuestionList[that.myQuestionListIndex].type == 'ai' &&
                that.myQuestionList[that.myQuestionListIndex].status == '0' &&
                (!that.myQuestionList[that.myQuestionListIndex].botChatId || that.myQuestionList[that.myQuestionListIndex].botChatId == '') &&
                (chatId && chatId != '')) {

                //保存sessionid
                that.$set(that.myQuestionList[that.myQuestionListIndex], 'botChatId', chatId)

            }
        },

        sendAiMsg(data, func) {
            const that = this

            if(!data || !that.$cmyUtils.trim(data)) {
                return
            }

            that.chat_data.push({
                direction: 'r',
                msg: data,
                time: that.$cmyUtils.getCurrentTime(),
                show_toolbar: false
            })

            // 小财思考
            that.chat_data.push({
                direction: 'l',
                msg: '思考中<span class="loading"></span>',
                time: that.$cmyUtils.getCurrentTime(),
                type: 'ai',
            })

            that.$forceUpdate()

            if (that.$refs['send-msg-textarea']) {
                that.$refs['send-msg-textarea'].innerHTML = ''
                setTimeout(()=>{
                    var ele = document.getElementById('chat-body');
                    ele.scrollTop = ele.scrollHeight;
                },200)
            }

            var botSessionId = that.myQuestionList.length > 0 ?  that.myQuestionList[that.myQuestionListIndex].ref_kid : ''
            doBaidu({
                url: that.$cmyUtils.buildUnitPath('uskit/bot/chat'),
                query: data,
                userId: that.staffId,
                sessionId: (botSessionId?botSessionId:''),
                chatType: that.channel,
                sluTags: that.channels_select,
                otherinfo: JSON.stringify({
                    img1: that.data_4_robot.img1,
                    img2: that.data_4_robot.img2,
                    img3: that.data_4_robot.img3
                }),
                devMode: true
            }).then(res => {
                if (res.errcode == that.$consts.biz.OK) {

                    //全新AI咨询刷新下咨询历史，并设置session_id
                    if(func) {
                        func(() => {

                            //保存sessionid
                            that.setAiSessionId(actions.session_id)

                        })
                    }

                    //机器人回答处理
                    var actions = res.data;
                    var answer = '';

                    //保存chatid
                    that.setAiChatId(actions.chat_id);

                    //保存sessionid
                    that.setAiSessionId(actions.session_id)

                    if (actions.type == 'failure') {

                        that.sendAiMsg4Chat(data, false)
                        return

                    } else if (actions.type == 'satisfy' || actions.type == 'failure'){
                        answer = answer + actions.say;

                    } else if (actions.type == 'guide' || actions.type == 'faqGuide'){

                        if(that.channel != 'cc') {
                            answer = answer + actions.say;
                            for (let i = 0; i < actions.option_list.length; i++)
                                answer = answer + '<div class="iconfont icon-kongxinwenhao huiwen-text-small-title huiwen-margin-top-bottom-5" id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;cursor:pointer;">' + actions.option_list[i].option + '</div>'
                        } else {

                            if (actions.type == 'faqGuide') {
                                answer = answer + '请问您是否想了解如下问题：' + '<span id="chatdata' + that.chat_data.length + '_0' + '" style="color:#1d88fa;font-weight: bold;margin:5px 0;">' + actions.option_list[0].option + '？</span>'
                            } else if (actions.type == 'guide') {

                                answer = answer + '请问您想了解哪方面的问题，您可以对我说：'
                                for (let i = 0; i < actions.option_list.length; i++) {
                                    answer = answer + '<span id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;margin:5px 0;">' + actions.option_list[i].option + '</span>'

                                    if (i != actions.option_list.length - 1)
                                        answer = answer + '、'
                                }

                            }
                        }

                    } else if (actions.type == 'clarify'){
                        answer = answer + actions.say
                    } else if (actions.type == 'event'){
                        answer = answer + actions.say
                    }else {
                        that.sendAiMsg4Chat(data, false)
                        return
                    }

                    //that.richText(answer, function(rt) {
                    //此处不用流，否则导致下方onclick时间无法设置
                        that.$set(that.chat_data, that.chat_data.length - 1, {
                            direction: 'l',
                            msg: answer,
                            time: that.$cmyUtils.getCurrentTime(),
                            show_toolbar: actions.type == 'satisfy' ? true : false,
                            type: 'ai'
                        })

                    //})

                    setTimeout(()=>{
                        var ele = document.getElementById('chat-body')
                        ele.scrollTop = ele.scrollHeight;

                        if (actions.option_list && actions.option_list.length > 0) {

                            for(let i=0;i<actions.option_list.length;i++) {
                                let answerNode = document.getElementById("chatdata" + (that.chat_data.length)  + '_' + i);
                                if (answerNode) {
                                    answerNode.onclick = () => {
                                        that.sendAiMsg(answerNode.innerText);
                                    }
                                }
                            }
                        }

                    },200)

                } else {
                    that.$message.error(res.errmsg)
                }
            })
        },

        richText: function(str, cb) {
            str = '<div style="overflow:hidden;">' + str + '</div>';
            const regex = /<.+?\/?>/g; // 匹配尖括号标签
            const matchArr = str.match(regex);
            //console.log("matchArr", matchArr);
            const specialChar = "|";
            const replaceStr = str.replace(regex, specialChar); // 标签数组
            const textArr = replaceStr.split(specialChar); // 文字数组
            //console.log("textArr", textArr);
            const strArr = []; // 存放处理过的文字数组
            let paraNum = 0; // 待替换参数个数
            const that = this;

            for (let text of textArr) {
                // 非空字符替换成类似 $[0-n] 参数
                if (text !== "") {
                    text = `$[${paraNum}]`;
                    paraNum += 1;
                }
                strArr.push(text);
            }
            //console.log("strArr", strArr);
            let templetStr = strArr.join(specialChar); // 数组转成待替换字符串
            //console.log("templetStr", templetStr);
            for (let index = 0; index < textArr.length; index++) {
                // 转换代替换字符串之后, 删除文字数组多余空字符
                if (textArr[index] === "") {
                    textArr.splice(index, 1);
                    index = index - 1;
                }
            }
            //console.log("textArr", textArr);
            while (templetStr.search(specialChar) !== -1) {
                // 数组转成的字符串原本 '特殊字符' 位置都是富文本标签的位置, 替换回标签
                if (matchArr[0]) {
                    templetStr = templetStr.replace(
                        specialChar,
                        matchArr[0].toString()
                    );
                    matchArr.splice(0, 1);
                } else {
                    templetStr = templetStr.replace(specialChar, ""); // 空字符串替换,防止死循环
                    //console.warn("matchArr not enough");
                    break;
                }
            }
            //console.log("templetStr", templetStr);
            const lastStrArr = []; // 转换后富文本数组
            const arrayParm = new Array(paraNum).fill(""); // 替换参数数组
            for (let i = 0; i < textArr.length; i++) {
                for (const text of textArr[i]) {
                    arrayParm[i] = arrayParm[i] + text;
                    let replaceStr1 = templetStr;
                    for (let index = 0; index < paraNum; index++) {
                        replaceStr1 = replaceStr1.replace(
                            `$[${index}]`,
                            arrayParm[index]
                        );
                    }
                    lastStrArr.push(replaceStr1);
                }
            }

            let lastStrIndex = 0;
            const func = () => {
                if (lastStrIndex >= lastStrArr.length) {
                    that.toBottom();
                    return;
                }
                const richtext = lastStrArr[lastStrIndex];
                //console.log("richtext", richtext);

                //设置
                if (cb) cb(richtext)

                //document.getElementById("box2").innerHTML = richtext;
                lastStrIndex += 1;
                setTimeout(() => {
                    func();
                }, 50);
            };

            setTimeout(() => {
                func();
            });
        },

        toBottom() {
            const that = this;
            setTimeout(()=>{
                var ele = document.getElementById('chat-body')
                if (ele)
                    ele.scrollTop = ele.scrollHeight;
            },200)
        },

        sendAiMsg4Chat(message = null, echo = true, func, ) {

            function doFail() {

                const that = this;

                if (that.chat_data.length > 0)
                    that.$set(that.chat_data, that.chat_data.length - 1, {
                        direction: 'l',
                        msg: '<div style="color:#e93d52;">很抱歉，我似乎出了一些问题，暂时无法响应您的请求。</div>',
                        time: that.$cmyUtils.getCurrentTime(),
                        show_toolbar: true,
                        type: 'chat'
                    })
                else
                    that.$set(that.chat_data, 0, {
                        direction: 'l',
                        msg: '<div style="color:#e93d52;">很抱歉，我似乎出了一些问题，暂时无法响应您的请求。</div>',
                        time: that.$cmyUtils.getCurrentTime(),
                        show_toolbar: false,
                        type: 'chat'
                    })

                that.toBottom();

            }

            const that = this
            var sendText = message

            if (!sendText) {
                return
            }

            //如果需要回显，否则静默调用
            if (echo && !(that.hide_first && that.chat_data.length == 0)) {

                //我说的话
                that.chat_data.push({
                    direction: 'r',
                    msg: sendText,
                    time: that.$cmyUtils.getCurrentTime(),
                })
                that.inputValue = null

                // 小财思考
                that.chat_data.push({
                    direction: 'l',
                    msg: '思考中<span class="loading"></span>',
                    time: that.$cmyUtils.getCurrentTime(),
                    type: 'ai',
                })

                that.toBottom()
            }

            else if(echo && that.hide_first && that.chat_data.length == 0){

                // 小财思考
                that.chat_data.push({
                    direction: 'l',
                    msg: '正在初始化，请稍候<span class="loading"></span>',
                    time: that.$cmyUtils.getCurrentTime(),
                    type: 'ai',
                })

            }

            //本次答案
            var answer = null;

            var botSessionId = that.myQuestionList.length > 0 ?  that.myQuestionList[that.myQuestionListIndex].ref_kid : ''
            var params = {
                query: sendText,
                max_tokens: 1200,
                session_id: (botSessionId ? botSessionId : ''),
                bot_type: 'tax'
            }

            doChat(
                params,

                //如果收到chunk，就输出
                function(res) {

                    if (!answer) {
                        answer = res;
                    } else {
                        answer = answer + res;
                    }

                    if (that.chat_data.length > 0)
                        that.$set(that.chat_data, that.chat_data.length - 1, {
                            direction:'l',
                            msg: answer.replace(/\n/g, '<br/>'),
                            time: that.$cmyUtils.getCurrentTime(),
                            type: 'chat'
                        })
                    else
                        that.$set(that.chat_data, 0, {
                            direction:'l',
                            msg: answer.replace(/\n/g, '<br/>'),
                            time: that.$cmyUtils.getCurrentTime(),
                            type: 'chat'
                        })

                    that.toBottom();

                },

                //最后成功完成，如何有错误，就提示
                function(res) {

                    res = JSON.parse(res.replace(/#ENDMSG#:/g, ''));

                    if (res.err_code == 0) {

                        //保存sessionid
                        if (res.data &&
                            res.data.session_id &&
                            res.data.session_id != '')

                            that.setAiSessionId(res.data.session_id);


                        //如果有错误信息
                        if (res.data && res.data.say && res.data.say != '') {

                            that.$message.error(res.data.say);

                        }

                        //没有错误信息
                        else {

                            //有sessionid才保存
                            if (that.myQuestionList.length > 0 && that.myQuestionList[that.myQuestionListIndex].ref_kid) {

                                //2. 格式化回答内容
                                that.formatResult();

                                //3. 保存消息内容
                                var msgdata = {};

                                if (that.hide_first && that.chat_data.length <= 1)
                                    msgdata.query = '';
                                else
                                    msgdata.query = sendText;

                                msgdata.message = that.$cmyUtils.trim(that.chat_data[that.chat_data.length - 1].msg);
                                msgdata.questiontype_code = '10';

                                //非常重要：双引号必须替换成\",这样stringify才会转换成\\\",插入后才会是\"
                                msgdata.query = msgdata.query.replace(/"/g, '\\"');
                                msgdata.message = msgdata.message.replace(/"/g, '\\"');

                                doAction({
                                    action_code: 'V4QJ20',
                                    param_str1: that.myStaffInfo.staff_id,
                                    param_str2: (that.myQuestionList.length > 0 && that.myQuestionList[that.myQuestionListIndex].botChatId ? that.myQuestionList[that.myQuestionListIndex].botChatId:''),
                                    param_str4: (that.myQuestionList.length > 0 && that.myQuestionList[that.myQuestionListIndex].ref_kid ? that.myQuestionList[that.myQuestionListIndex].ref_kid:''),
                                    param_str5: JSON.stringify(msgdata)
                                }).then(res => {
                                    if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {

                                        if (that.myQuestionList.length > 0 && !that.myQuestionList[that.myQuestionListIndex].botChatId)
                                            that.setAiChatId(res.data.errmsg);

                                    }
                                })
                            }

                        }

                    } else {

                        that.$message.error(res.err_msg);
                    }

                },

                //发生其他异常，则失败
                function() {
                    doFail();
                }
            )

        },

        formatResult() {

            const that = this;
            var result = that.$cmyUtils.trim(that.chat_data[that.chat_data.length - 1].msg);
            var regex = '';

            /*软件代码处理*/
            regex = /```[\s\S]*?```/g;
            if (regex.test(result)) {
                result = result.replace(regex, (match) => `<div class="inline-content-code">${match}</div>`);
                that.$set(that.chat_data[that.chat_data.length - 1], 'msg', result);
                return;
            }

            /*会计分录处理*/
            regex = /借：.*?<br\/>/m;
            if (regex.test(result)) {
                result = result.replace(regex, (match) => `<div class="inline-content-entry">${match}`);
                regex = /贷：.*?(?=<br\/>|$)/gm;

                let lastMatch;
                let lastMatchIndex;
                let match;
                while ((match = regex.exec(result)) !== null) {

                    lastMatch = match[0];
                    lastMatchIndex = match.index;
                }

                if (lastMatch) {
                    let start = lastMatchIndex;
                    let end = start + lastMatch.length;
                    result = result.substring(0, start) + `${lastMatch}</div>` + result.substring(end);
                }

                that.$set(that.chat_data[that.chat_data.length - 1], 'msg', result);
            }

            /*对列表做处理*/
            regex = /(^|<br\/>)(\d+\.\s+.*?)(?=<br\/>|$)/gm;
            result = result.replace(regex, (match) => {
                match = match.replace(/<br\/>/g, '');
                return `<div class="inline-content-list">${match}</div>`
            });
            that.$set(that.chat_data[that.chat_data.length - 1], 'msg', result);

            return;

        },

        refreshBotSessionLog(func) {
            const that = this;
            var botChatId = that.myQuestionList[that.myQuestionListIndex].request_id
            var botSessionId = that.myQuestionList[that.myQuestionListIndex].ref_kid

            doQuery({
                query_code: (botSessionId ? 'SLBL10' : 'SLBL11'),
                param_str5: (botSessionId ? botSessionId : botChatId),
                page: 1,
                limit: 5
            }).then(res => {
                if (res.errcode == '0' && res.data) {

                    //聊天历史内容
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].message = JSON.parse(res.data[i].message)

                        var obj = {
                            direction: res.data[i].action_type == "query" ? 'r' : 'l',
                            time: res.data[i].create_time,
                            boImg: false,
                            type: 'ai',
                            otherinfo: res.data[i].otherinfo,
                        }

                        //如果是发送消息
                        if (obj.direction == 'r') {
                            obj.msg = res.data[i].message.content
                        } else {
                            obj.msg = res.data[i].message.say

                            //对接收到的消息做处理，排除failure情况
                            if (res.data[i].message.type != 'failure')
                                for (let j = 0; j < res.data[i].message.option_list.length; j++) {
                                    obj.msg = obj.msg +
                                        '<div class="huiwen-margin-bottom-5"><a class="iconfont icon-kongxinwenhao wcs-text-small" style="color:#1d88fa;"/> <a text="' +
                                        res.data[i].message.option_list[j].option +
                                        '" style="color:#1d88fa;">' + res.data[i].message.option_list[j]
                                            .option +
                                        '</a></div>'
                                }
                        }

                        obj.msg = that.formatAnswer(obj.msg)
                        that.chat_data.push(obj)
                    }

                    //设置ai咨询状态（此时肯定有res.data）
                    if (res.data.length > 0) {
                        that.$set(that.myQuestionList[that.myQuestionListIndex], 'botSessionStatus', res.data[0].botSessionStatus)
                    }

                    //如果当前content为空，则恢复下;用于后续转人工提交信息
                    if ((!that.data_4_robot.content || that.data_4_robot.content == '') &&
                        that.chat_data &&
                        that.chat_data.length>0) {
                        that.data_4_robot.content = that.chat_data[0].msg;

                        if (that.chat_data[0].otherinfo) {
                            var otherinfo = JSON.parse(that.chat_data[0].otherinfo)
                            if (otherinfo) {
                                that.data_4_robot.img1 = otherinfo.img1;
                                that.data_4_robot.img2 = otherinfo.img2;
                                that.data_4_robot.img3 = otherinfo.img3;
                            }
                        }
                    }

                    //如果没有sessionid，则恢复一下；用于后续转人工保存到人工咨询记录中
                    if (!botSessionId || botSessionId == '') {
                        for (var i = 0; i < res.data.length; i++) {
                            if (res.data[i].ai_session_id && res.data[i].ai_session_id != '') {
                                that.$set(that.myQuestionList[that.myQuestionListIndex], 'ref_kid', res.data[i].ai_session_id)
                                break;
                            }
                        }
                    }

                    setTimeout(()=>{
                        var ele = document.getElementById('chat-body');
                        ele.scrollTop = ele.scrollHeight;
                    },200)

                    //callback
                    if (func) func();
                }
            })
        },

        goBack() {
            const that = this
            if(that.checkMsg) {
                clearInterval(that.checkMsg)
                that.checkMsg = null
            }
            that.$router.go(-1)
        },

        openOrResetNoticeSocket() {
            const that = this
            if(!that.boNwsValid) {
                that.INIT_NOTICE_WS({
                    getSockMsg: (data) => {
                        var myQuestionList = [...that.myQuestionList]

                        if(data.type == '10' || data.type == '20') {

                            var index = 0
                            for(var i = 0; i < myQuestionList.length; i++) {

                                if(myQuestionList[i].request_id == data.request_id &&
                                    i != that.myQuestionListIndex) {

                                    myQuestionList[i].unread = data.unread

                                    //如果是20消息，设置listid
                                    if (data.type == '20' &&
                                        !myQuestionList[i].request_listid &&
                                        data.request_listid)
                                        myQuestionList[i].request_listid = data.request_listid;

                                    index = i
                                    break
                                }
                            }

                            that.myQuestionList = myQuestionList

                            that.$notification['info']({
                                message: '消息提醒',
                                description: '您有一条新的回复消息，请注意查看并及时回复',
                                onClick:() =>{
                                    that.showChatData(index)
                                }
                            })

                        }
                    },
                    openCallback: () =>{

                    },
                    errorCallback: () => {

                    },
                    acctId: that.staffId
                })
            }
            else {
                that.RESET_NOTICE_WS_ONMESSAGE((data)=>{
                    var myQuestionList = [...that.myQuestionList]

                    if(data.type == '10' || data.type == '20') {
                        var index = 0
                        for(var i = 0; i < myQuestionList.length; i++) {

                            if(myQuestionList[i].request_id == data.request_id &&
                                i != that.myQuestionListIndex) {

                                myQuestionList[i].unread = data.unread

                                //如果是20消息，设置listid
                                if (data.type == '20' &&
                                    !myQuestionList[i].request_listid &&
                                    data.request_listid)
                                    myQuestionList[i].request_listid = data.request_listid;

                                index = i
                                break
                            }
                        }
                        that.myQuestionList = myQuestionList

                        that.$notification['info']({
                            message: '消息提醒',
                            description: '您有一条新的回复消息，请注意查看并及时回复',
                            onClick:() =>{
                                that.showChatData(index)
                            }
                        })

                    }
                })
            }
        },

        loadMyAwardHistory(page = this.myQuestionListPage, func) {
            const that = this
            doQuery({
                query_code: 'LNQA10',
                param_str3: that.keywords || '',
                page: page,
                limit: that.myQuestionListLimit
            }).then(res => {
                if(res.errcode >= 0 && res.data) {

                    res.data.forEach(item => {
                        if(dateUtils.getToday() == item.create_time.substring(0,10)) {
                            item.create_time_format = item.create_time.substring(10,16)
                        }else {
                            item.create_time_format = item.create_time.substring(0,10)
                        }
                    })

                    that.myQuestionListEnd = res.data.length < that.myQuestionListLimit
                    if(page == 1) {
                        that.myQuestionList = res.data
                    }else {
                        that.myQuestionList = [...that.myQuestionList,...res.data]
                    }
                    that.myQuestionListPage = page
                    that.boAdopted = null

                    var boNeedRefresh = false

                    for(var i = 0; i < res.data.length; i++) {
                        if(res.data[i].status == '9') {
                            boNeedRefresh = true
                            break
                        }
                    }

                    if(boNeedRefresh) {
                        setTimeout(() => {
                            that.loadMyAwardHistory(page,func)
                        },1000)
                    } else {
                        if(func) {
                            func()
                        }else if(page == 1) {
                            that.showChatData(0)
                        }
                    }
                }
            })
        },

        refreshAddinfo(func) {
            const that = this
            const { myQuestionListIndex, myQuestionList } = that
            doQuery({
                query_code: 'SLA8X',
                param_str2: myQuestionList[myQuestionListIndex].request_id,
                page: 1,
                limit: 1
            }).then(res=>{
                if (res.errcode >= 0 && res.data && res.data.length > 0) {
                    if (res.data[0].endtime_diff > 0) {
                        res.data[0].endtime_diff_sum = {
                            days: Math.floor(res.data[0].endtime_diff / (3600 * 24)),
                            hours: Math.floor((res.data[0].endtime_diff % (3600 * 24)) / 3600),
                            minutes: Math.floor(((res.data[0].endtime_diff % (3600 * 24)) % 3600) / 60)
                        }
                    }
                    that.requestAddinfo = res.data[0]
                    myQuestionList[myQuestionListIndex].master = res.data[0].master
                    myQuestionList[myQuestionListIndex].master_headimgurl = res.data[0].master_headimgurl
                    that.$set(that.myQuestionList,myQuestionListIndex,myQuestionList[myQuestionListIndex])
                    that.leftHeader = res.data[0].master_headimgurl
                    if (func) {
                        func()
                    }
                }else{
                    that.$message.error(res.errmsg)
                }
            })
        },

        refreshRewardMsg() {
            const that = this
            const { myQuestionListIndex, myQuestionList } = that

            getRewardMsg({
                request_id: myQuestionList[myQuestionListIndex].request_id,
                request_listid: myQuestionList[myQuestionListIndex].request_listid || '',
            }).then(res => {

                if(res.errcode >= 0) {

                    if (res.reward.is_sender == "1") {
                        if (res.reply.province != null && res.reply.city != null && res.reply.province != '' && res.reply.city != '') {
                            that.leftLocation = res.reply.province + " " + res.reply.city
                        }
                        that.leftHeader = that.requestAddinfo.master_headimgurl
                        that.leftName = that.requestAddinfo.master
                        that.rightHeader = that.myInfo.headimgurl
                        that.boAdopted = res.reply.is_adopted_ == "1"
                        that.boEvalueated = res.reply.is_evalueated_ == "1"
                    }

                    //判断上一条是不是机器人消息，如果是，插入一条提示消息
                    if (that.chat_data &&
                        that.chat_data.length > 0 &&
                        that.chat_data[that.chat_data.length - 1].type == 'ai') {
                        that.showTip('转接人工专家')
                        setTimeout(()=>{
                            var ele = document.getElementById('chat-body');
                            ele.scrollTop = ele.scrollHeight;
                        },200)
                    }

                    for (var i = 0; i < res.list.length; i++) {
                        if (res.list[i].detail_ == null || res.list[i].detail_ == 'adopted_success') {
                            continue
                        }

                        //命令消息
                        if (res.list[i].detail_ == '#closed_success#') {
                            res.list[i].detail_ = '<span class="huiwen-text-red">【问题关闭】</span>'
                            myQuestionList[myQuestionListIndex].status = 'e'
                            myQuestionList[myQuestionListIndex].status_desc = '问题已关闭'
                            that.$set(that.myQuestionList,myQuestionListIndex,myQuestionList[myQuestionListIndex])
                        }

                        //命令消息
                        if (res.list[i].detail_ == '#forward_success#') {
                            res.list[i].detail_ = '<span class="huiwen-text-red">【问题转接】</span>'
                            setTimeout(() => {
                                that.refreshAddinfo()
                            },500)
                        }

                        res.list[i].detail_ = switchEmoji(res.list[i].detail_)
                        var obj = {
                            msg: res.list[i].detail_,
                            direction: res.list[i].direction_.toLowerCase() == 'l' ? 'r' : 'l',
                            time: res.list[i].create_time_,
                            boImg: false,
                            boFile: false
                        }
                        if (res.list[i].detail_.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS) == 0 || res.list[i].detail_.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS_SAFE) == 0) {
                            obj.boImg = true
                        }
                        if (res.list[i].detail_.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH) == 0 || res.list[i].detail_.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH_HTTPS) == 0) {
                            obj.boFile = true
                        }
                        that.chat_data.push(obj)
                    }

                    that.updateAskTimes()

                    if(that.checkMsg) {
                        clearInterval(that.checkMsg)
                        that.checkMsg = null
                    }

                    if (JSON.stringify(res.reply) != "{}") {
                        that.boNewChat = false
                        that.openWebSocket()
                    }else {
                        that.boNewChat = true
                        that.checkMsg = setInterval(() => {
                            that.checkFirstReply(myQuestionListIndex)
                        },10000)
                    }

                    setTimeout(()=>{
                        var ele = document.getElementById('chat-body');
                        ele.scrollTop = ele.scrollHeight;
                    },200)

                }

            })
        },

        updateAskTimes() {
            const that = this
            //找出第一次专家回复
            var first = 0;
            for (first = 0; first < that.chat_data.length; first++) {
                if (that.chat_data[first].direction == 'l' &&
                    that.chat_data[first].msg != '【问题转接】' &&
                    that.chat_data[first].msg != '【问题关闭】'
                ) {
                    break
                }
            }

            //从第一次专家回复向后计算追问次数
            var asktimes = 0;
            for (let i = first + 1; i < that.chat_data; i++) {
                if (that.chat_data[i].direction == 'r') {
                    ++asktimes
                }
            }

            //如果不限制次数
            if (that.requestAddinfo.service_times == null) {
                that.requestAddinfo.service_times_used = asktimes
                that.requestAddinfo.service_times_leave = 9999
            }else {
                that.requestAddinfo.service_times_used = asktimes
                that.requestAddinfo.service_times_leave = (that.requestAddinfo.service_times - asktimes) >= 0 ? (that.requestAddinfo.service_times - asktimes) : 0
            }

        },

        checkFirstReply(myQuestionListIndex) {
            const that = this
            var requestId = that.myQuestionList[myQuestionListIndex].request_id
            if(that.$route.name != 'StudentManualConsult') {
                if(that.checkMsg) {
                    clearInterval(that.checkMsg)
                    that.checkMsg = null
                }
                return
            }

            if(that.myQuestionList[that.myQuestionListIndex].request_id != requestId) {
                if(that.checkMsg) {
                    clearInterval(that.checkMsg)
                    that.checkMsg = null
                }
                return
            }

            if(!that.boNewChat) {
                if(that.checkMsg) {
                    clearInterval(that.checkMsg)
                    that.checkMsg = null
                }
                return
            }

            doQuery({
                query_code: 'SLM10',
                param_str2: requestId,
                page: 1,
                limit: 5
            }).then(res=>{
                if (res.errcode >= 0 && res.data && res.data.length > 0) {
                    //如果request_listid发生了变化，则刷新数据，并且停止
                    if (res.data[0].request_listid && res.data[0].request_listid != '') {
                        that.myQuestionList[that.myQuestionListIndex].request_listid = res.data[0].request_listid
                        that.refreshAddinfo(() => {
                            that.refreshRewardMsg()
                        })

                        //停止定时器
                        if (that.checkMsg) {
                            clearInterval(that.checkMsg);
                            that.checkMsg = null;
                        }
                    }
                }else{
                    that.$message.error(res.errmsg)
                }
            })

        },

        openWebSocket() {
            const that = this
            var hide = that.$message.loading('正在连接...', 0)
            const { myQuestionListIndex, myQuestionList, socketMsgQueue } = that

            getRewardMsg({
                request_id: myQuestionList[myQuestionListIndex].request_id,
                request_listid: myQuestionList[myQuestionListIndex].request_listid || '',
                action: "connect"
            }).then(res => {
                if (res.errcode >= 0) {
                    that.INIT_WS({
                        getSockMsg: (data, requestId)=>{
                            that.getMsg(data,requestId)
                        },
                        openCallback: ()=>{
                            hide()
                            socketMsgQueue.forEach(item => {
                                that.SEND_MSG(item)
                            })
                            that.socketMsgQueue = []
                        },
                        errorCallback: ()=>{
                            that.openWebSocket()
                        },
                        requestId: myQuestionList[myQuestionListIndex].request_id,
                        websocket_key: res.websocket_key
                    })
                }
            }).finally(() => {
                setTimeout(() => {
                    hide()
                },2000)
            })

        },

        pasting(e) {
            let that = this
            const cbd = e.clipboardData;
            const ua = window.navigator.userAgent;
            if ( !(e.clipboardData && e.clipboardData.items) ) {
                return ;
            }
            if(cbd.items[0].type.startsWith('image')) {

                if(that.myQuestionList && that.myQuestionList[that.myQuestionListIndex].type == 'ai'){
                    that.$message.error('AI提问暂时只支持发送文字消息')
                    return;
                }

                for(let i = 0; i < cbd.items.length; i++) {
                    let item = cbd.items[i];
                    if(item.kind == "file"){
                        // blob 就是从剪切板获得的文件，可以进行上传或其他操作
                        const blob = item.getAsFile();
                        console.log('获得blob', blob);
                        if (blob.size === 0) {
                            return;
                        }else {
                            that.uploadRequestImage({file: blob})
                        }
                    }
                }
            } else if(cbd.items[0].type.startsWith('text')){
                var div = that.$refs['send-msg-textarea'];
                cbd.items[0].getAsString(text => {
                    div.innerHTML = div.innerHTML + text
                    that.content = that.content + text
                    that.$cmyUtils.keepLastIndex(div)
                })
            }
        },

        btnSendMsg() {
            const that = this
            var content = that.$refs['send-msg-textarea'].innerHTML
            if (that.myQuestionList.length > 0 && that.myQuestionList[that.myQuestionListIndex].type == 'ai') {
                that.sendAiMsg(content)
            } else {
                that.sendQuestion(content)
            }
        },

        enterText(event) {
            const that = this
            if (event.keyCode == 13) {
                var content = that.$refs['send-msg-textarea'].innerHTML
                if (that.myQuestionList.length > 0 && that.myQuestionList[that.myQuestionListIndex].type == 'ai') {
                    that.sendAiMsg(content)
                } else {
                    that.sendQuestion(content)
                }
                event.preventDefault() // 阻止浏览器默认换行操作
                return false
            }
        },

        getMsg(data, requestId) {
            const that = this
console.log(data)
console.log(requestId)
            if(requestId != that.myQuestionList[that.myQuestionListIndex].request_id) {
                for(let i=0; i < that.myQuestionList.length; i++) {
                    if(that.myQuestionList[i].request_id == requestId) {
                        that.myQuestionList[i].unread = that.myQuestionList[i].unread + 1
                        break
                    }
                }
                return
            }

            if (data == null || data.trim() == "") {
                return
            }

            if (data == 'ERRCODE#1023') {
                that.$message.error('消息未成功发送，可能含有敏感词')
                return
            }

            if (data.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS) == 0 || data.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS_SAFE) == 0) {
                that.chat_data.push({
                    direction: 'l',
                    msg: data,
                    time: that.$cmyUtils.getCurrentTime(),
                    boImg: true,
                    boFile: false
                })
            }else if(data.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH) == 0 || data.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH_HTTPS) == 0) {
                that.chat_data.push({
                    direction: 'l',
                    msg: data,
                    time: that.$cmyUtils.getCurrentTime(),
                    boImg: false,
                    boFile: true
                })
            }else {
                data = switchEmoji(data)

                //如果收到的是采纳的通知消息
                if (data && data == "adopted_success") {
                    that.boAdopted = true
                    that.$success({
                        title: '恭喜！您的回答已被采纳'
                    })
                }else if (data && data  == '#closed_success#') {
                    that.chat_data.push({
                        direction: 'l',
                        msg: '<span class="huiwen-text-red">【问题关闭】</span>',
                        time: that.$cmyUtils.getCurrentTime(),
                        boImg: false,
                        boFile: false
                    })
                    that.myQuestionList[that.myQuestionListIndex].status = 'e'
                    that.myQuestionList[that.myQuestionListIndex].status_desc = '问题已关闭'
                }else if (data && data  == '#forward_success#') {
                    that.chat_data.push({
                        direction: 'l',
                        msg: '<span class="huiwen-text-red">【问题转接】</span>',
                        time: that.$cmyUtils.getCurrentTime(),
                        boImg: false,
                        boFile: false
                    })
                    that.transfering = true

                    that.refreshAddinfoAfterTransfer()

                    that.CLOSE_WS()

                    var task = setInterval(() => {
                        if(!that.boQwsValid) {
                            that.openWebSocket()
                            clearInterval(task)
                            setTimeout(() => {
                                that.transfering = false
                            },500)
                        }
                    },1000)
                }else {
                    that.chat_data.push({
                        direction: 'l',
                        msg: data,
                        time: that.$cmyUtils.getCurrentTime(),
                        boImg: false,
                        boFile: false
                    })
                }
            }


            that.$forceUpdate()

            that.updateAskTimes()

            setTimeout(()=>{
                var ele = document.getElementById('chat-body');
                ele.scrollTop = ele.scrollHeight;
            },200)
        },

        refreshAddinfoAfterTransfer(func) {
            const that = this
            const { myQuestionListIndex, myQuestionList } = that
            doQuery({
                query_code: 'SLA8X',
                param_str2: myQuestionList[myQuestionListIndex].request_id,
                page: 1,
                limit: 1
            }).then(res=>{
                if (res.errcode >= 0 && res.data && res.data.length > 0) {
                    // 当且仅当master改变了才做操作
                    if(myQuestionList[myQuestionListIndex].master != res.data[0].master) {
                        if (res.data[0].endtime_diff > 0) {
                            res.data[0].endtime_diff_sum = {
                                days: Math.floor(res.data[0].endtime_diff / (3600 * 24)),
                                hours: Math.floor((res.data[0].endtime_diff % (3600 * 24)) / 3600),
                                minutes: Math.floor(((res.data[0].endtime_diff % (3600 * 24)) % 3600) / 60)
                            }
                        }
                        that.requestAddinfo = res.data[0]
                        myQuestionList[myQuestionListIndex].master = res.data[0].master
                        myQuestionList[myQuestionListIndex].master_headimgurl = res.data[0].master_headimgurl
                        that.$set(that.myQuestionList,myQuestionListIndex,myQuestionList[myQuestionListIndex])
                        that.leftHeader = res.data[0].master_headimgurl
                        if(func) {
                            func()
                        }
                    }else {
                        setTimeout(() => {
                            that.refreshAddinfoAfterTransfer(func)
                        },1000)
                    }
                }else{
                    that.$message.error(res.errmsg)
                }
            })
        },

        doDownload(url) {
            var that = this
            var agent = navigator.userAgent.toLowerCase();
            var isMac = /macintosh|mac os x/i.test(navigator.userAgent);

            //获取最新版本号,先打开窗口，防止后面被浏览器阻止弹出窗口
            var dlWindow = window.open("about:blank","_blank")
            dlWindow.location.href = url
        },

        showEvaluateDialog() {
            const that = this
            that.starLength = 5
            that.evaluateObj = {
                a: false,
                b: false,
                c: false,
                d: false,
                e: false,
                f: false
            },
                that.evaluateText = undefined
            that.evaluateVisible = true
        },

        evaluateSumbit() {
            const that = this
            const { myQuestionListIndex, myQuestionList } = that
            var viewtypeCodes = ''
            var array = []
            var index = 1
            for (var i in this.evaluateObj){
                if (this.evaluateObj[i]){
                    array.push(index)
                }
                index++
            }
            if(array.length==0){
                viewtypeCodes = '';
            }else{
                for(var i = 0 ; i < array.length;i++){
                    if(i==(array.length-1)){
                        viewtypeCodes+=array[i]
                    }else{
                        viewtypeCodes += (array[i]+"#")
                    }
                }
            }

            doAction({
                action_code: 'SL00',
                param_int1: that.starLength,
                param_str3: viewtypeCodes,
                param_str4: (that.evaluateText ? that.evaluateText:''),
                param_str2: that.myQuestionList[that.myQuestionListIndex].request_id
            }).then(res => {
                if(res.errcode >= 0) {
                    that.$message.success('评价成功')
                    that.evaluateVisible = false
                    that.requestAddinfo.is_evaluated = 1
                }
            })

        },

        clickEmoji(item) {
            let that = this
            var div = that.$refs['send-msg-textarea'];
            div.innerHTML = div.innerHTML + item.text;
            that.content = that.content + item.text;
            that.$cmyUtils.keepLastIndex(div)
            that.emojiUl = false;
        },

        /**
         * 发送图片
         * @param param
         */
        uploadRequestImage(param) {
            var fileObj = param.file;
            var that = this;
            that.doUploadImg(param, (data) => {
                that.sendQuestion(data.img_url)
            })
        },

        sendQuestion(question) {
            const that = this
            const { myQuestionListIndex, myQuestionList, boNewChat } = that

            /*****by ycp****/

            //检查专家有没有答复
            let has_answered = false;

            if (that.chat_data) {
                for (let ci = 0; ci < that.chat_data.length; ci++) {

                    //如果存在一个专家回答，而且不是命令消息，则代表已经回答过
                    if ( that.chat_data[ci].direction == 'l' &&
                        that.chat_data[ci].type != 'ai' &&
                        that.chat_data[ci].type != 'info' &&
                        that.chat_data[ci].type != 'ctrl' &&
                        that.chat_data[ci].msg != '【问题转接】' &&
                        that.chat_data[ci].msg != '【问题关闭】'
                    ) {
                        has_answered = true;
                        break;
                    }

                }
            }

            if (!has_answered) {
                that.$message.error('请等待专家回复后进行追问')
                return;
            }
            /********************/

            if(!question || !that.$cmyUtils.trim(question) || myQuestionList.length == 0) {
                return
            }

            var content = switchExpression(question)
            if(boNewChat) {
                replyRequestReward({
                    content: content.msg,
                    request_id: myQuestionList[myQuestionListIndex].request_id
                }).then(res => {
                    if(res.errcode > 0) {
                        that.boNewChat = false
                        myQuestionList[myQuestionListIndex].request_listid = res.request_listid_
                        that.$set(that.myQuestionList,myQuestionListIndex,myQuestionList[myQuestionListIndex])
                        that.openWebSocket()
                    }else {
                        that.$message.error(res.errmsg)
                    }
                })

            } else {
                if(that.boQwsValid) {
                    that.SEND_MSG(content.msg)
                }else {
                    that.socketMsgQueue.push(content.msg)
                    //尝试重新链接
                    that.openWebSocket();
                }
            }
            that.sendMsg(content.htl)
        },

        sendMsg(data) {
            const that = this
            if(!data || !that.$cmyUtils.trim(data)) {
                return
            }

            //如果是命令消息，不显示
            if (data && (data == '#closed_success#' || data == '#forward_success#')){
                return;
            }

            if(data.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS) == 0 || data.indexOf(that.$consts.imgs.BASE_PATH_CMCLOUDS_SAFE) == 0) {
                that.chat_data.push({
                    direction: 'r',
                    msg: data,
                    time: that.$cmyUtils.getCurrentTime(),
                    boImg: true,
                    boFile: false
                })
            }else if(data.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH) == 0|| data.indexOf(that.$consts.imgs.BASE_PATH_OSS_ATTACH_HTTPS) == 0)  {
                data = switchEmoji(data)
                that.chat_data.push({
                    direction: 'r',
                    msg: data,
                    time: that.$cmyUtils.getCurrentTime(),
                    boImg: false,
                    boFile: true
                })
            } else {
                that.chat_data.push({
                    direction: 'r',
                    msg: data,
                    time: that.$cmyUtils.getCurrentTime(),
                    boImg: false,
                    boFile: false
                })
            }

            that.$forceUpdate()
            that.$refs['send-msg-textarea'].innerHTML = ''

            that.updateAskTimes()
            setTimeout(()=>{
                var ele = document.getElementById('chat-body');
                ele.scrollTop = ele.scrollHeight;
            },200)
        },

        doUploadImg(param, func) {
            var that = this;
            var fileObj = param.file;
            var hide = that.$message.loading('图片上传中...', 0)

            qUpload(that.$consts.url.QUPLOAD_IMG,fileObj)
                .then(res => {
                    if (res.data.appcode == '1') {
                        if (func) {
                            func(res.data)
                        }
                    } else {
                        that.$message.error(res.data.appmsg)
                    }
                })
                .finally(() => {
                    hide()
                })
        },

        formatAnswer(inanswer){

            var answer = inanswer;

            //一些格式的标准化
            answer = answer.replace(/<kdetail kid="/g,
                '<a class="kdetail-link" href="http://');
            answer = answer.replace(/<\/kdetail>/g, '<\/a>');
            answer = answer.replace(/color: rgb\(255, 0, 0\)/g,
                'font-weight:bold');
            answer = answer.replace(/color:rgb\(255, 0, 0\)/g,
                'font-weight:bold');
            answer = answer.replace(/color:\#ff0000/g,
                'font-weight:bold');
            answer = answer.replace(/color: \#ff0000/g,
                'font-weight:bold');
            answer = answer.replace(/color:\#FF0000/g,
                'font-weight:bold');
            answer = answer.replace(/color: \#FF0000/g,
                'font-weight:bold');
            answer = answer.replace(/color: rgb\(0, 0, 255\)/g,
                'font-weight:bold');
            answer = answer.replace(/color:rgb\(0, 0, 255\)/g,
                'font-weight:bold');
            answer = answer.replace(/color:\#0000ff/g,
                'font-weight:bold');
            answer = answer.replace(/color: \#0000ff/g,
                'font-weight:bold');
            answer = answer.replace(/color:\#0000FF/g,
                'font-weight:bold');
            answer = answer.replace(/color: \#0000FF/g,
                'font-weight:bold');
            answer = answer.replace(/font-size: 16px/g, 'font-size: 14px');
            answer = answer.replace(/font-size: 18px/g, 'font-size: 14px');
            answer = answer.replace(/font-size:18px/g, 'font-size: 14px');
            answer = answer.replace(/font-size: 20px/g, 'font-size: 14px');
            answer = answer.replace(/font-size:20px/g, 'font-size: 14px');
            answer = answer.replace(/font-size: 22px/g, 'font-size: 14px');
            answer = answer.replace(/font-size:22px/g, 'font-size: 14px');
            answer = answer.replace(/font-size:16.0pt/g, 'font-size: 14px');

            return answer;
        },
    },

    beforeRouteLeave(to,from,next) {
        const that = this
        that.CLOSE_WS()

        if(!that.boNwsValid) {
            that.INIT_NOTICE_WS({
                getSockMsg:(data) => {
                    if(data.type == '10'||data.type == '20') {

                        that.$notification['info']({
                            message: '消息提醒',
                            description: '您有一条新的回复消息，请注意查看并及时回复',
                            onClick:() =>{
                                that.$router.push({name: 'StudentManualConsult'})
                            }
                        })

                    }
                },
                openCallback: () =>{

                },
                errorCallback: () => {

                },
                acctId: that.staffId
            })
        }else {
            that.RESET_NOTICE_WS_ONMESSAGE((data)=>{
                if(data.type == '10'||data.type == '20') {
                    that.$notification['info']({
                        message: '消息提醒',
                        description: '您有一条新的回复消息，请注意查看并及时回复',
                        onClick:() =>{
                            that.$router.push({name: 'StudentManualConsult'})
                        }
                    })
                }
            })
        }

        next()
    }
}
