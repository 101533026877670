import { qRequestPost, qRequestGet, qRequestUpload } from '@/utils/qRequest'
import consts from '@/constants'

export function qQuery (params) {
    return qRequestPost(
        consts.url.DO_QUERY_Q,
        params
    )
}

export function qAction (params) {
    return qRequestPost(
        consts.url.DO_ACTION_Q,
        params
    )
}

export function qUpload(url,file) {
    var formData = new FormData()
    formData.append('img',file)
    return qRequestUpload(url,'img',file)
}

export function addRequestReward (params) {
    return qRequestGet(
        consts.url.ADD_REQUEST_REWARD,
        params
    )
}

export function getRewardMsg (params) {
    return qRequestGet(
        consts.url.GET_REWARD_MSG,
        params
    )
}

export function replyRequestReward (params) {
    return qRequestGet(
        consts.url.REPLY_REQUEST_REWARD,
        params
    )
}