/*
 * emoji转换expression
 */
const switchExpression = function(str){
  var obj={
    msg:str,
    htl:str
  }
  var emoji = ["[发怒]", "[撇嘴]", "[疑问]", "[得意]",
    "[流泪]", "[骷髅]", "[呲牙]", "[微笑]", "[憨笑]",
    "[比心]", "[白眼]", "[难过]", "[发呆]", "[衰]", "[调皮]", "[愉快]", "[爱心]","[惊恐]","[惊讶]","[擦汗]","[敲打]"]
  var expression = [">:o "/*发怒*/, ":-[ "/*撇嘴*/, "?:| "/*疑问*/, "B-) "/*得意*/,
    ":.( "/*流泪*/,  "]:) "/*骷髅*/, ":-D "/*呲牙*/, ":-) "/*微笑*/, ":^0 "/*憨笑*/,
    ":x "/*比心*/, ";\\ "/*白眼*/, ":-( "/*难过*/, ":| "/*发呆*/, ":0 "/*衰*/, ":-p "/*调皮*/, ";-) "/*愉快*/, "(heart) "/*爱心*/,"[惊恐] ","[惊讶] ","[擦汗] ","[敲打] "]
  var extest = ["\\[发怒\\]", "\\[撇嘴\\]", "\\[疑问\\]", "\\[得意\\]",
    "\\[流泪\\]", "\\[骷髅\\]", "\\[呲牙\\]", "\\[微笑\\]", "\\[憨笑\\]",
    "\\[比心\\]", "\\[白眼\\]", "\\[难过\\]", "\\[发呆\\]", "\\[衰\\]", "\\[调皮\\]", "\\[愉快\\]", "\\[爱心\\]","\\[惊恐\\]","\\[惊讶\\]","\\[擦汗\\]","\\[敲打\\]"]
  var imgAdress=["11","01","32","04","05","37","13","00","28","91","22","15","03","36","12","21","66","26","14","40","38"]
  for (var i = 0; i < emoji.length; i++) {
    if (str.indexOf(emoji[i]) >= 0) {
      obj.msg = obj.msg.replace(new RegExp(extest[i], 'g'), expression[i])
      obj.htl = obj.htl.replace(new RegExp(extest[i], 'g'), "<img src= \""+process.env.BASE_URL+ "emojis/" +imgAdress[i]+".gif\">");
    }
  }
  return obj;
}

/*
 * expression表情转换emoji
 */
const switchEmoji = function(str){

  var expression = [">:o"/*发怒*/, "]:o"/*发怒*/, ":-["/*撇嘴*/, "?:|"/*疑问*/, "B-)"/*得意*/,
    ":'("/*流泪*/, ":.("/*流泪*/, "]:)"/*骷髅*/, ":-D"/*呲牙*/, ":-)"/*微笑*/, ":^0"/*憨笑*/,
    ":x"/*比心*/, ";\\"/*白眼*/, ":-("/*难过*/, ":|"/*发呆*/, ":0"/*衰*/, ":-p"/*调皮*/, ";-)"/*愉快*/, "(heart)"/*爱心*/,"[惊恐]","[惊讶]","[擦汗]","[敲打]"]
  var extest = [">:o"/*发怒*/, "\\]:o"/*发怒*/, ":-\\["/*撇嘴*/, "\\?:\\|"/*疑问*/, "B-\\)"/*得意*/,
    ":'\\("/*流泪*/, ":.\\("/*流泪*/, "\\]:\\)"/*骷髅*/, ":-D"/*呲牙*/, ":-\\)"/*微笑*/, ":\\^0"/*憨笑*/,
    ":x"/*比心*/, ";\\\\"/*白眼*/, ":-\\("/*难过*/, ":\\|"/*发呆*/, ":0"/*衰*/, ":-p"/*调皮*/, ";-\\)"/*愉快*/, "\\(heart\\)"/*爱心*/,"\\[惊恐\\]","\\[惊讶\\]","\\[擦汗\\]","\\[敲打\\]"]
  var imgAdress=["11","11","01","32","04","05","05","37","13","00","28","91","22","15","03","36","12","21","66","26","14","40","38"]

  /*add ycp: 转换本地表情用*/
  var emoji = ["[发怒]", "[撇嘴]", "[疑问]", "[得意]",
    "[流泪]", "[骷髅]", "[呲牙]", "[微笑]", "[憨笑]",
    "[比心]", "[白眼]", "[难过]", "[发呆]", "[衰]", "[调皮]", "[愉快]", "[爱心]","[惊恐]","[惊讶]","[擦汗]","[敲打]"]
  var extestemoji = ["\\[发怒\\]", "\\[撇嘴\\]", "\\[疑问\\]", "\\[得意\\]",
    "\\[流泪\\]", "\\[骷髅\\]", "\\[呲牙\\]", "\\[微笑\\]", "\\[憨笑\\]",
    "\\[比心\\]", "\\[白眼\\]", "\\[难过\\]", "\\[发呆\\]", "\\[衰\\]", "\\[调皮\\]", "\\[愉快\\]", "\\[爱心\\]","\\[惊恐\\]","\\[惊讶\\]","\\[擦汗\\]","\\[敲打\\]"]
  var imgAdressemoji=["11","01","32","04","05","37","13","00","28","91","22","15","03","36","12","21","66","26","14","40","38"]

  for (var i = 0; i < expression.length; i++) {
    if (str.indexOf(expression[i]) >= 0) {
      str= str.replace(new RegExp(extest[i], 'g'), "<img src=\""+process.env.BASE_URL+ "emojis/" +imgAdress[i]+".gif\">");
    }
  }

  /*add ycp: 如果返回的也带有本地表情，也转换*/
  for (var i = 0; i < emoji.length; i++) {
    if (str.indexOf(emoji[i]) >= 0) {
      str= str.replace(new RegExp(extestemoji[i], 'g'), "<img src=\""+process.env.BASE_URL+ "emojis/" +imgAdress[i]+".gif\">");
    }
  }
  return str;
}

export {
  switchEmoji,
  switchExpression
}
