import axios from 'axios';
import consts from '../constants'
import store from '../store'
import cmyUtils from './cmyUtils'
import router from '../router'
import { notification,message } from 'ant-design-vue'

// 该文件暂时只支持请求无需token的接口

const qService = axios.create({
  baseURL: '/q',
  timeout: 10000
})

const beforeQRequest = (config) => {

  let params = null
  // get
  if (config.method === 'get') {
    params = config.params ? config.params : {}
  } else if (config.method === 'post') {
    params = config.data ? config.data : {}
  }

  // 如果token正常,将token放入header中
  var accessToken = store.getters.qToken
  if (accessToken != null) {
    params.token = accessToken
  }
  params.source = 'boot'

  if (config.method === 'post') {
    config.data = JSON.stringify(params)
  } else if (config.method === 'get') {
    config.params = params
  }
  return config
}

/**
 * 请求拦截器：请求错误
 */

function beforeRequestErr(error){
  return Promise.reject(error);
}

/**
 * 响应拦截器：响应处理之前
 */
function beforeResponse(response){return response;}


const beforeResponseErr = (error) => {
  if (String(error).substr(-3) === '401' || String(error).substr(-3) === '500' || String(error).substr(-3) === '403') {
    message.error('请求失败，请联系管理员')
  } else {
    console.log('请求异常：' + String(err))
  }
  return Promise.reject(error)
}

qService.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
qService.defaults.withCredentials = true
qService.interceptors.request.use(beforeQRequest, beforeRequestErr)
qService.interceptors.response.use(beforeResponse, error => { return Promise.reject(error) })


function qRequestGet(url, params) {
  return new Promise((resolve, reject) => {
    qService.get(url,{
      params: params,
      data: {}
    })
      .then(response1 => {
        resolve(response1.data)
      },err => {
        if (String(err).substr(-3) == '401' || String(err).substr(-3) == '403') {
          if(store.getters.mobilephone) {
            store.dispatch('QLogin', store.getters.mobilephone)
                .then(() => {
                  qService.get(url, {
                    params: params,
                    data: {}
                  }).then(response2 => {
                    resolve(response2.data);
                  }, err => {
                    reject(err);
                  });
                })
          }
        }
      })
  })
}

function qRequestPost(url, params) {
  return new Promise((resolve, reject) => {
    qService.post(url,params)
      .then(response1 => {
        resolve(response1.data)
      },err => {
        if (String(err).substr(-3) == '401' || String(err).substr(-3) == '403') {
          if(store.getters.mobilephone) {
            store.dispatch('QLogin', store.getters.mobilephone)
                .then(() => {
                  qService.post(url, params).then(response2 => {
                    resolve(response2.data);
                  }, err => {
                    reject(err);
                  });
                })
          }
        }
      })
  })
}

const uploadService = axios.create({
  baseURL: '/q',
  timeout: 15000,
  headers: {
    'Content-Type': 'multipart/form-data;charset=UTF-8',
  }
})



function qRequestUpload(url,name,file) {
  let formData = new FormData();
  formData.append(name,file);
  return uploadService.post(url + '?token='+store.getters.qToken,formData)
}


export {
  qRequestGet,
  qRequestPost,
  qRequestUpload
}
